.team .member {
  text-align: center;
  border-radius: 10px;
  padding: 15px;
  overflow: hidden;
}

.team .row {
  justify-content: center;
}

.team .member img {
  border-radius: 8px;
  overflow: hidden;
}

.team .member .member-content {
  padding: 0 20px 30px 20px;
}

.team .member h4 {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 2px;
  font-size: 20px;
  /* color: #ffffff; */
}
.team .member span {
  /* color: #ffffff; */
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 14px;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #6c757d;
}

.team .member .social {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.team .member .social a {
  color: #a2a2a2;
  transition: 0.3s;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a:hover {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.team .member .social i {
  font-size: 14px;
  margin: 0 2px;
}
.single-page .team h4,
.single-page .team span {
  color: #000000;
}
.single-page .recent-posts .post-category,
.single-page .recent-posts .title a:hover {
  color: var(--color-primary);
}
