.header {
  transition: all 0.5s;
  z-index: 997;
  height: 80px;
  background: var(--color-primary);
}

.header.sticked {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 80px;
  box-shadow: 0 0 29px 0 rgb(0 0 0 / 5%);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo {
  width: 226px;
  height: 80px;
  background: url(../../assets/logo.svg) center center;
  background-size: contain;
  /* background-position: bottom; */
  background-repeat: no-repeat;
}

.header .logo h1 {
  font-size: 30px;
  /* background: url(../../assets/logo.svg) center center; */
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #ffffff;
  font-family: var(--font-primary);
}

.header .logo h1 span {
  color: #ffffff;
}
