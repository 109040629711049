.the-main-service .our-offer-carousel .owl-dots .owl-dot span {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid;
  height: 15px;
  margin: 0 5px;
  width: 15px;
}

.the-main-service .our-offer-carousel .owl-dots .owl-dot.active span {
  background: var(--color-primary) none repeat scroll 0 0;
  border-color: var(--color-primary);
}

.the-main-service .item {
  background: #ffffff none repeat scroll 0 0;
  border-left: 2px solid var(--color-primary);
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #e4e4e4;
  overflow: hidden;
  padding: 30px;
  position: relative;
  z-index: 1;
  border-radius: 8px;
}

.the-main-service.text-center .item {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  padding: 67px 40px 64px;
  height: 100%;
}

.the-main-service.text-center .item i {
  background: #ffffff none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: var(--color-primary);
  font-size: 40px;
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  width: 80px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  margin-bottom: 25px;
}

.the-main-service.text-center .item i::after {
  border: 2px solid var(--color-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: '';
  height: 90px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 90px;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.the-main-service.item-border-less .item {
  border: medium none;
}

.the-main-service .our-offer-items.less-carousel .equal-height {
  margin-bottom: 30px;
}

.the-main-service.item-border-less .item .number {
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 30px;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item,
.the-main-service.center-active .single-item:nth-child(2n) .item {
  background: var(--color-primary) none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item i,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item h4,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item p,
.the-main-service.center-active .single-item:nth-child(2n) .item i,
.the-main-service.center-active .single-item:nth-child(2n) .item h4,
.the-main-service.center-active .single-item:nth-child(2n) .item p {
  color: #ffffff;
}

.the-main-service .item i {
  color: var(--color-primary);
  display: inline-block;
  font-size: 60px;
  margin-bottom: 20px;
}

.the-main-service .item h4 {
  font-weight: 600;
  text-transform: capitalize;
}

.the-main-service .item p {
  margin: 0;
}

.the-main-service .item i,
.the-main-service .item h4,
.the-main-service .item p {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.the-main-service .item::after {
  background: var(--color-primary) none repeat scroll 0 0;
  content: '';
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: -1;
}

.the-main-service .item:hover::after {
  left: 0;
}

.the-main-service .item:hover i,
.the-main-service .item:hover h4,
.the-main-service .item:hover p {
  color: #ffffff !important;
}

.the-main-service.text-center .item:hover i::after {
  border-color: #ffffff !important;
}

.the-main-service.text-center .item:hover i {
  background-color: var(--color-primary) !important;
  color: #ffffff !important;
}

.the-main-service.text-left .item i {
  background: var(--color-primary) none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  z-index: 1;
  text-align: center;
}

.the-main-service.text-left .item i::after {
  border: 2px solid var(--color-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: '';
  height: 120px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 120px;
}

.additional-height {
  margin: 64px 0;
}
