/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@font-face {
  font-family: Comfortaa;
  src: url('assets/fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf');
}
@font-face {
  font-family: Poppins;
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
}

:root {
  --font-default: 'Poppins', sans-serif;
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Poppins', sans-serif;
  --font-heading: 'Comfortaa', cursive;
}

:root {
  --color-default: #222222;
  --color-primary: #e89719;
  --color-secondary: #222222;
}

:root {
  scroll-behavior: smooth;
}

:root {
  --color-white: #ffffff;
  --color-light: #f1f5f9;
  --color-black: #121212;
  --color-night: #001632;
  --color-red: #f44336;
  --color-blue: #1a73e8;
  --color-gray: #80868b;
  --color-grayish: #dadce0;
  --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html body {
  font-family: var(--font-default);
  color: var(--color-default);
}

body a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

dl,
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.mt-20 {
  margin-top: 20px;
}
.seprator {
  margin: 0 20px;
}
/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 90px 0;
  overflow: hidden;
}

.section {
  padding: 90px 0;
  overflow: hidden;
}

.sections-bg {
  background:
    linear-gradient(rgb(232 151 25 / 86%), rgb(232 151 25 / 86%)),
    url(./assets/images/cta-bg.jpg) center center;
  background-size: cover;
  padding: 100px 60px;
  border-radius: 8px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.sections-bg h2,
.sections-bg p,
.sections-bg a,
.sections-bg i,
.sections-bg span,
.sections-bg h4 {
  color: #ffffff !important;
}

.section-header {
  text-align: center;
  padding-bottom: 60px;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  color: var(--color-default);
}

.section-header p {
  margin-bottom: 0;
  color: #5f5f5f;
}

.fill-btn {
  background: var(--color-secondary);
  border: 0;
  padding: 14px 45px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.section-header h2 span {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background: var(--color-secondary);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
  border: none;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(248, 90, 64, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 2px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1.5s linear infinite;
  animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
