/*--------------------------------------------------------------
# About Us Section
--------------------------------------------------------------*/
.about h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

.about .call-us {
  left: 10%;
  right: 10%;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
  text-align: center;
}

.about .call-us h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.about .call-us p {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-primary);
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 15px 21px 15px 20px;
  position: relative;
  color: rgb(19 20 20 / 80%);
  background: #f3f3f3;
  margin-bottom: 5px;
  border-radius: 8px;
}

.about .content ul i {
  color: var(--color-primary);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--color-primary) 50%,
    rgba(0, 131, 116, 0.4) 52%
  );
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 131, 116, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes load {
  from {
    width: 0%;
  }
}

@-webkit-keyframes load {
  from {
    width: 0%;
  }
}

@-moz-keyframes load {
  from {
    width: 0%;
  }
}

@-o-keyframes load {
  from {
    width: 0%;
  }
}

.bar {
  background-color: rgb(220 220 220 / 18%);
  padding: 1px;
  border-radius: 15px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #ffffff;
}

.bar::before {
  content: attr(data-skill);
  background-color: var(--color-primary);
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.bar.front::before {
  background-color: var(--color-primary);
}

.bar.back::before {
  background-color: var(--color-primary);
}

.bar.learning::before {
  width: calc(20% - 10px);
}

.bar.basic::before {
  width: calc(40% - 10px);
}

.bar.intermediate::before {
  width: calc(60% - 10px);
}

.bar.advanced::before {
  width: calc(80% - 10px);
}

.bar.expert::before {
  width: calc(100% - 10px);
}
