/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  position: relative;
  background: url(../../assets/images/hero-bg.jpg) center center;
  background-size: cover;
  padding: 60px 0 0 0;
}

.hero::before {
  content: '';
  width: 100%;
  position: absolute;
  background: url(../../assets/images/hero-after.png) center center;
  background-size: 100%;
  background-position: bottom;
  bottom: -7px;
  background-repeat: no-repeat;
  height: 100vh;
  z-index: 0;
}

.hero .caption {
  height: 80vh;
  margin-top: 0;
}

.hero .circle {
  color: #ffffff;
}

@media (min-width: 1365px) {
  .hero {
    background-attachment: fixed;
  }
}

.fill-button {
  background: var(--color-secondary);
  border: 0;
  padding: 14px 45px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.hero h2 {
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: var(--font-heading);
  text-transform: uppercase;
}

.hero p {
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 30px;
}
.hero h3 {
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 30px;
}

.hero .btn-get-started {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 40px;
  border-radius: 50px;
  transition: 0.3s;
  color: var(--color-primary);
  background: rgb(255 255 255);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

.hero .btn-get-started:hover {
  border-color: rgba(255, 255, 255, 0.5);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #ffffff;
  font-weight: 400;
}

.hero .btn-watch-video i {
  color: #ffffff;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .hero .caption {
    justify-content: center !important;
    height: unset;
    margin-top: 100px;
  }

  .hero h2 {
    font-size: 36px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }

  .icon-boxes .card-two {
    margin-top: var(--bs-gutter-y);
    margin-bottom: 0px;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  .hero::after {
    display: none;
  }
}

@media (min-width: 768px) {
  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }

  .icon-boxes .card-two {
    margin-top: -20px;
    margin-bottom: -20px;
  }
}

.top-icon-box .icon-boxes {
  padding-bottom: 60px;
}

@media (min-width: 1200px) {
  .top-icon-box .icon-boxes:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50% + 20px);
    background-color: #fff;
  }
}

.top-icon-box .icon-box {
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 2px 0 rgb(8 66 152 / 33%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  text-align: center;
}

.top-icon-box .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.top-icon-box .icon-box .title a {
  color: var(--color-primary);
  transition: 0.3s;
}

.top-icon-box .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: rgb(0 0 0 / 60%);
}

.top-icon-box .icon-box:hover {
  background: #222222;
}

.top-icon-box .icon-box:hover .title a,
.top-icon-box .icon-box:hover p,
.top-icon-box .icon-box:hover .icon {
  color: #fff;
}
