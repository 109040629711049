.topbar {
  height: 45px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
  background: var(--color-primary);
}

.topbar .contact-info i {
  font-style: normal;
  color: #ffffff;
  line-height: 0;
}

.topbar .contact-info i a,
.topbar .contact-info i span {
  padding-left: 5px;
  color: #ffffff;
}

@media (max-width: 575px) {
  .topbar .contact-info i a,
  .topbar .contact-info i span {
    font-size: 13px;
  }
}

@media (max-width: 787px) {
  section.topbar {
    display: none !important;
  }
}

.topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

.topbar .contact-info i a:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

.topbar .social-links a {
  color: #ffffff;
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

.topbar .social-links a:hover {
  color: #414141;
}
